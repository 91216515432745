<template>
  <div>
    <div class="m_banner">
      <div class="m_banner_text">等级评审，推动医院跨越式发展<br />医院等级评审辅导咨询项目</div>
      <!-- <div class="m_banner_i"></div>
      <div class="m_banner_text2">五定一做一分析，精准战略定位和发展方向</div> -->
    </div>
    <div class="review_m_box">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">项目介绍</span>
        <span class="index_title_after"></span>
      </div>
      <img class="review_m_img" src="../assets/img/review_01.png" alt="">
      <div class="review_mod_content">
        <div class="review_content_item">
          <div class="review_content_title">效益</div>
          <div class="review_content_introduce">
            同行认同一-规范技术能力、同质化服务政府认同一社会效益最大化、全民健康寻找可持续发展潜力。
          </div>
        </div>
        <div class="review_content_item">
          <div class="review_content_title">目标</div>
          <div class="review_content_introduce">
            以评促建，提升管理水平，资源效益最大化提高医院信誉。
          </div>
        </div>
        <div class="review_content_item">
          <div class="review_content_title">风向标</div>
          <div class="review_content_introduce">
            医院等级评审是目前国内对医院进行综合能力评价的通用方式，是引导医院发展的“风向标”。通过医院等级评审工作，建立医院管理长效机制，为群众提供安全，有效，方便，低廉的医疗为啥服务。
          </div>
        </div>
      </div>
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">项目内容</span>
        <span class="index_title_after"></span>
      </div>
      <div class="review_m_project">
        <div class="review_tab">
          <div :class="['review_tab_item', tab == 0 ? 'review_tab_item2' : '']" @click="tab = 0">
            过程控制
          </div>
          <div :class="['review_tab_item', tab == 1 ? 'review_tab_item2' : '']" @click="tab = 1">
            常见困难
          </div>
          <div :class="['review_tab_item', tab == 2 ? 'review_tab_item2' : '']" @click="tab = 2">
            运作模式
          </div>
          <div :class="['review_tab_item', tab == 3 ? 'review_tab_item2' : '']"  @click="tab = 3">
            服务品质
          </div>
        </div>
        <div v-if="tab == 0">
          <div class="modern_m_principle_list">
            <div class="modern_m_principle_item">
              <div class="modern_m_principle_left">
                <img class="modern_m_principle_img" src="../assets/img/principle_bg1.png" alt="">
                <div class="modern_m_principle_index">01</div>
              </div>
              <div class="modern_m_principle_right">
                <div class="modern_m_principle_name">
                  <img src="../assets/img/principle_bg5.png" alt="">
                  准备阶段
                </div>
                <div class="modern_m_principle_introduce">
                  条文现状调查<br />
                  创建办构建<br />
                  例会管理及督办机制<br />
                  文件管理机制
                </div>
              </div>
            </div>
            <div class="modern_m_principle_item">
              <div class="modern_m_principle_right">
                <div class="modern_m_principle_name">
                  <img src="../assets/img/principle_m3.png" alt="">
                  训练强化
                </div>
                <div class="modern_m_principle_introduce">
                  核心条款现场访查<br />
                  质控体系构建及质量工具辅导<br />
                  病例质量追踪调查
                </div>
              </div>
              <div class="modern_m_principle_left">
                <img class="modern_m_principle_img" src="../assets/img/principle_bg3.png" alt="">
                <div class="modern_m_principle_index">02</div>
              </div>
            </div>
            <div class="modern_m_principle_item">
              <div class="modern_m_principle_left">
                <img class="modern_m_principle_img" src="../assets/img/principle_bg4.png" alt="">
                <div class="modern_m_principle_index">03</div>
              </div>
              <div class="modern_m_principle_right">
                <div class="modern_m_principle_name">
                  <img src="../assets/img/principle_m4.png" alt="">
                  模拟评审
                </div>
                <div class="modern_m_principle_introduce">
                  院内专家组构建<br />
                  自评机制建立<br />
                  追踪检查法实例演示<br />
                  模拟评审
                </div>
              </div>
            </div>
            <div class="modern_m_principle_item">
              <div class="modern_m_principle_right">
                <div class="modern_m_principle_name">
                  <img src="../assets/img/principle_m2.png" alt="">
                  评审冲刺
                </div>
                <div class="modern_m_principle_introduce">
                  问题追踪整改<br />
                  迎评手册<br />
                  迎评技巧培训辅导
                </div>
              </div>
              <div class="modern_m_principle_left">
                <img class="modern_m_principle_img" src="../assets/img/principle_bg2.png" alt="">
                <div class="modern_m_principle_index">04</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tab == 1">
          <img style="width: 100%" src="../assets/img/review_15.png" alt="">
        </div>
        <div v-if="tab == 2">
          <div class="index_subhead">科学的项目运作模式确保项目顺利推进并达到预期效果</div>
          <img style="width: 100%" src="../assets/img/review_14.png" alt="">
        </div>
        <div v-if="tab == 3">
          <div class="index_subhead">目标明确，藉由评审，让医院管理能力，医疗质量服务品质得到整体提升</div>
          <div>
            <div class="review_quality">
              <div class="review_quality_item">
                <img class="review_quality_icom" src="../assets/img/review_10.png" alt="">
                <div class="review_quality_name">核心指导思想</div>
                <div class="review_quality_introduce">
                  <p>促进医院加强自身建设和管理,提高医疗质量，保证医疗安全</p>
                </div>
              </div>
              <div class="review_quality_item">
                <img class="review_quality_icom" src="../assets/img/review_11.png" alt="">
                <div class="review_quality_name">辅导目的</div>
                <div class="review_quality_introduce">
                  <p>以核心条文深入辅导为主</p>
                  <p>构建三级质控体系并运作</p>
                  <p>有效推动登记评审工作</p>
                  <p>针对薄弱环节进行补强</p>
                </div>
              </div>
              <div class="review_quality_item">
                <img class="review_quality_icom" src="../assets/img/review_12.png" alt="">
                <div class="review_quality_name">项目过程突出持续改进理念</div>
                <div class="review_quality_introduce">
                  <p>有计划，有制度，有规划</p>
                  <p>有学习，有培训，有授权</p>
                  <p>有措施，有落实，有成效</p>
                  <p>有检查，有分析，有反馈</p>
                  <p>有整改，有提高，有再修订（制度），有再培训</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">辅导过程</span>
        <span class="index_title_after"></span>
      </div>
      <div>
        <div class="review_tab">
          <div :class="['review_tab_item', tab1 == 0 ? 'review_tab_item2' : '']" @click="tab1 = 0">
            项目辅导过程
          </div>
          <div :class="['review_tab_item', tab1 == 1 ? 'review_tab_item2' : '']" @click="tab1 = 1">
            评审辅导流程
          </div>
          <div :class="['review_tab_item', tab1 == 2? 'review_tab_item2' : '']" @click="tab1 = 2">
            辅导计划
          </div>
        </div>
        <div v-if="tab1 == 0">
          <div class="development_m_cycle">
            <div class="development_m_cycle_item">
              <div class="development_m_cycle_name">01</div>
              <div class="development_m_cycle_introduce">
                <p>帮助医院建立科学的迎评机制，有计划，系统的推进评审工作。</p>
              </div>
            </div>
            <img class="development_m_cycle_img" src="../assets/img/government_12.png" alt="" />
            <div class="development_m_cycle_item">
              <div class="development_m_cycle_name">02</div>
              <div class="development_m_cycle_introduce">
                <p>以评审工作为重点，提升医院内部管理，推动医疗质量持续改进。</p>
              </div>
            </div>
            <img class="development_m_cycle_img" src="../assets/img/government_17.png" alt="" />
            <div class="development_m_cycle_item">
              <div class="development_m_cycle_name">03</div>
              <div class="development_m_cycle_introduce">
                <p>实时追踪评审筹备进度及计划执行效果。</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tab1 == 1">
          <img style="width: 100%" src="../assets/img/review_17.png" alt="">
        </div>
        <div v-if="tab1 == 2">
          <img style="width: 100%" src="../assets/img/review_16.png" alt="">
        </div>
      </div>
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">我们的服务</span>
        <span class="index_title_after"></span>
      </div>
      <div>
        <div class="review_tab">
          <div :class="['review_tab_item', tab2 == 0 ? 'review_tab_item2' : '']" @click="tab2 = 0">
            服务保障
          </div>
          <div :class="['review_tab_item', tab2 == 1 ? 'review_tab_item2' : '']" @click="tab2 = 1">
            服务内容
          </div>
          <div :class="['review_tab_item', tab2 == 2? 'review_tab_item2' : '']" @click="tab2 = 2">
            服务计划
          </div>
        </div>
        <div>
          <div v-if="tab2 == 0">
            <div class="review_mod_content">
              <div class="review_content_item">
                <div class="review_content_title1">售后服务</div>
                <div class="review_content_introduce1">
                  1、项目完成后一年内免费提供一次现场指导<br />
                  2、终身享受免费远程指导<br />
                  3、免费提供公司管理月刊及成功经典案例汇编
                </div>
              </div>
              <div class="review_content_item">
                <div class="review_content_title2">增值服务</div>
                <div class="review_content_introduce1">
                  1、搭建样板医院考察学习的桥梁<br />
                  2、优惠参加公司举办的各种学术研讨会和培训<br />
                  3、提供服务品牌建设，绩效成本管理，第三方满意度调查等增值产品
                </div>
              </div>
            </div>
          </div>
          <div v-if="tab2 == 1">
            <img style="width: 100%" src="../assets/img/review_18.png" alt="">
            <div class="index_subhead">致和咨询为每所医院的医疗服务管理项目提供个性化工作计划，其工作内容精确到天数和人数，进而高效确认服务费用。</div>
          </div>
          <div v-if="tab2 == 2" style="padding: 0 37px">
            <img style="width: 100%" src="../assets/img/review_19.png" alt="">
            <div>
              <div class="review_service_div">医院管理水平整体提升</div>
              <div class="review_service_div">医院服务质量和流程逐步完善</div>
              <div class="review_service_div">医院服务质量和流程逐步优化</div>
              <div class="review_service_div">医院质量与安全管理不断优化</div>
              <div class="review_service_div">文化建设和发展规划有序推进</div>
              <div class="review_service_div"> 医院质量与安全管理不断优化</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="serve_m_box">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">服务案例</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">易策医管集团已累计为超过<span style="color: #2D43F2;font-weight: 500">800+</span>家医院提供优质服务</div>
      <div class="serve_m_type">
        <div
          :class="['serve_m_type_item', isType == item.type ? 'serve_m_type_isItem' : '']"
          v-for="(item, index) in hisTypeList"
          :key="index" 
          @click="changeTab(item.type)"
        >
          {{ item.value }}
        </div>
      </div>
      <div class="serve_m_hospital">
        <div class="serve_m_hospital_item" v-for="(item, index) in hisList2" :key="index" @click="getDetails(item.hospitalId)">
          <img class="serve_m_hospital_img" :src="item.hospitalLogo" />
          <div class="serve_m_hospital_name">{{ item.hospitalName }}</div>
        </div>
      </div>
      <div class="index_more" @click="stretch">{{ hisShow ? '收起' : '查看更多' }}</div>
    </div>
  </div>
</template>

<script>
  import { getHospitalList } from "@/api/index";
  export default {
    props: [''],
    data() {
      return {
        is_big: false,
        isType: null,
        hisShow: false,
        hisTypeList: [
          { type: null, value: "全部" },
          { type: "综合医院", value: "综合医院" },
          { type: "中医医院", value: "中医医院" },
          { type: "妇幼保健院", value: "妇幼保健院" },
          { type: "其他类型", value: "其他类型" },
        ],
        hisList2: [],
        tab: 0,
        tab1: 0,
        tab2: 0
      }
    },
    created() {
      this.changeTab(null)
    },
    methods: {
      changeTab(i) {
        this.isType = i;
        let data = {
          // hospitalProvince: this.HisTilter,
          hospitalType: i,
        };
        getHospitalList(data).then((res) => {
          if (res.code == 200) {
            this.hisList2 = res.rows.slice(0, 10);
            this.hisListCopy = JSON.parse(JSON.stringify(res.rows))
            this.hisShow = false
          }
        });
      },
      stretch(){
        this.hisShow = !this.hisShow
        if(this.hisShow == false){
          this.hisList2 = this.hisListCopy.slice(0, 10)
        }else{
          this.hisList2 = this.hisListCopy
        }
      },
      getDetails(hospitalId) {
        this.$router.push({
          path: "/serve",
          query: { activeName:2, hospitalId: hospitalId },
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .m_banner {
    width: 100%;
    height: 515px;
    background-image: url("../assets/img/mobile_bg11.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .m_banner_text {
      font-size: 42px;
      color: #fff;
      text-align: center;
      padding-top: 200px;
    }
    .m_banner_i {
      width: 45px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto 16px;
    }
    .m_banner_text2 {
      font-size: 26px;
      color: #fff;
      text-align: center;
    }
  }
  .index_title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #000;
    margin: 40px auto;
    .index_title_span {
      margin: 0 20px;
    }
    .index_title_after {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
    .index_title_before {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .index_subhead {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: #333;
    margin: 23px auto;
  }
  .serve_m_box {
    background-color: #fff;
    padding: 50px 37px;
    .serve_m_type {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 60px;
      .serve_m_type_item {
        width: fit-content;
        padding: 21px 30px;
        background: #FFFFFF;
        border-radius: 10px;
        border: 1px solid #BFBFBF;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
      }
      .serve_m_type_isItem {
        color: #fff;
        border: 1px solid #2D43F2;
        background: #2D43F2;
      }
    }
  }
  .serve_m_hospital {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 41px;
    .serve_m_hospital_item {
      width: 48%;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 3px 16px 1px rgba(0,14,52,0.06);
      border-radius: 4px;
      border: 2px solid #E6E6E6;
      margin-bottom: 28px;
      padding: 21px;
      .serve_m_hospital_img {
        width: 138px;
        height: 138px;
      }
      .serve_m_hospital_name {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        padding-top: 20px;
      }
    }
  }
  .index_more {
    width: 175px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    border: 2px solid #999999;
    font-size: 22px;
    color: #666;
    text-align: center;
    margin: 20px auto 0;
  }
  .review_m_box {
    padding: 37px;
    .review_m_img {
      width: 622px;
      height: 493px;
      display: block;
      margin: 0 auto;
    }
    .review_mod_content {
      padding: 0 37px;
      .review_content_item {
        margin-top: 42px;
        .review_content_title {
          font-size: 26px;
          font-weight: bold;
          color: #333333;
          position: relative;
          padding-left: 50px;
          &::before {
            content: "";
            width: 24px;
            height: 20px;
            background-image: url("../assets/img/review_02.png");
            background-repeat: no-repeat;
            background-size: 100%;
            position: absolute;
            top: 9px;
            left: 0;
          }
        }
        .review_content_title1 {
          font-size: 26px;
          font-weight: bold;
          color: #333333;
          position: relative;
          padding-left: 60px;
          &::before {
            content: "";
            width: 39px;
            height: 39px;
            background-image: url("../assets/img/review_08.png");
            background-repeat: no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .review_content_title2 {
          font-size: 26px;
          font-weight: bold;
          color: #333333;
          position: relative;
          padding-left: 60px;
          &::before {
            content: "";
            width: 39px;
            height: 39px;
            background-image: url("../assets/img/review_07.png");
            background-repeat: no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .review_content_introduce {
          font-size: 26px;
          font-weight: 400;
          color: #5a5a5a;
          margin-top: 20px;
        }

        .review_content_introduce1 {
          font-size: 24px;
          font-weight: 400;
          color: #5a5a5a;
          margin-top: 15px;
          line-height: 36px;
        }
      }
    }
    .review_m_project {
      padding: 0 37px;
      .modern_m_principle_list {
        .modern_m_principle_item {
          display: flex;
          align-items: center;
          margin: 60px 0;
          .modern_m_principle_left {
            position: relative;
            .modern_m_principle_img {
              width: 192px;
              height: 205px;
            }
            .modern_m_principle_index {
              position: absolute;
              top: 0;
              left: 0;
              font-size: 53px;
              font-weight: bold;
              color: #FFFFFF;
              width: 192px;
              height: 205px;
              line-height: 205px;
              text-align: center;
              z-index: 2;
            }
            &:nth-child(odd) {
              margin-right: 30px;
            }
            &:nth-child(even) {
              margin-left: 30px;
            }
          }
          .modern_m_principle_right {
            width: calc(100% - 222px);
            padding-left: 40px;
            .modern_m_principle_name {
              font-size: 30px;
              font-weight: bold;
              color: #333333;
              margin-bottom: 14px;
              img {
                width: 25px;
                height: 25px;
                margin-left: -30px;
              }
            }
            .modern_m_principle_introduce {
              font-size: 28px;
              font-weight: 400;
              color: #333333;
              line-height: 42px;
            }
          }
        }
      }
    }
  }
  .review_tab {
    display: flex;
    justify-content: center;
    padding-bottom: 55px;
    .review_tab_item {
      padding: 16px 0px;
      border-bottom: 4px solid transparent;
      margin: 0 23px;
      cursor: pointer;
      transition: all 0.5s;
      font-size: 26px;
      font-weight: bold;
    }

    .review_tab_item2 {
      border-bottom: 4px solid #3370FF;
      color: #3370FF;
    }
  }
  .review_quality {
    .review_quality_item {
      height: 428px;
      background: #ffffff;
      box-shadow: 0px 3px 10px 1px rgba(146, 146, 146, 0.16);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      border: 1px solid #e1e1e1;
      padding: 21px;
      margin-bottom: 45px;
      .review_quality_icom {
        width: 68px;
        display: block;
        margin: 0 auto;
      }
      .review_quality_name {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        margin: 17px 0;
      }
      .review_quality_introduce {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        padding: 0 40px;
        p {
          margin: 11px 0;
          padding-left: 50px;
          position: relative;
          &::before {
            content: "";
            width: 22px;
            height: 22px;
            position: absolute;
            top: 2px;
            left: 0;
            background-image: url("../assets/img/review_13.png");
            background-repeat: no-repeat;
            background-size: 100%;
          }
        }
      }
    }
  }
  .development_m_cycle {
      padding: 10px 30px;
      .development_m_cycle_item {
        background: #FFFFFF;
        box-shadow: 0px 3px 16px 1px rgba(167,167,167,0.2);
        border: 1px solid #E1E1E1;
        .development_m_cycle_name {
          font-size: 30px;
          font-weight: bold;
          color: #FFFFFF;
          text-align: center;
          height: 81px;
          line-height: 81px;
        }
        .development_m_cycle_introduce {
          font-size: 26px;
          font-weight: 400;
          color: #333333;
          line-height: 41px;
          padding: 32px 62px;
        }
        &:nth-child(1) {
          .development_m_cycle_name {
            background-color: #C43694;
          }
        }
        &:nth-child(3) {
          .development_m_cycle_name {
            background-color: #684AB7;
          }
        }
        &:nth-child(5) {
          .development_m_cycle_name {
            background-color: #2C4FDB;
          }
        }
      }
      .development_m_cycle_img {
        width: 17px;
        height: 40px;
        transform: rotate(90deg);
        margin: 0 auto;
        display: block;
      }
    }
    .review_service_div {
      padding: 0 30px 0 40px;
      font-size: 30px;
      font-weight: 400;
      color: #333333;
      position: relative;
      margin-top: 35px;
      &::before {
        content: "";
        width: 26px;
        height: 22px;
        position: absolute;
        top: calc(50% - 5px);
        left: 0;
        background-image: url("../assets/img/review_02.png");
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
</style>
