<template>
  <div>
    <div class="pc_review">
      <div class="banner-box">
        <div class="banner-boxs">
          <div class="banner-name">
            等级评审，推动医院跨越式发展<br />医院等级评审辅导咨询项目
          </div>
          <div class="banner-bnt" @click="openPDF">下载资料</div>
        </div>
      </div>
      <div class="review-box">
        <!-- 项目介绍 -->
        <div class="content-box" v-scroll-reveal>
          <div class="plan-title">
            <div class="flex title2">
              <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
              <span>项目介绍</span>
              <img
                class="titleIcon1 rotate180"
                src="../assets/img/titleIcon1.png"
                alt=""
              />
            </div>
            <div class="flex mes2">
              <img src="../assets/img/titleIcon2.png" alt="" />
            </div>
          </div>
          <div class="review-mod">
            <img
              class="review-mod-img"
              src="../assets/img/review_01.png"
              alt=""
            />
            <div class="review-mod-content">
              <div class="review-content-item">
                <div class="review-content-title">效益</div>
                <div class="review-content-introduce">
                  同行认同一-规范技术能力、同质化服务政府认同一社会效益最大化、全民健康寻找可持续发展潜力。
                </div>
              </div>
              <div class="review-content-item">
                <div class="review-content-title">目标</div>
                <div class="review-content-introduce">
                  以评促建，提升管理水平，资源效益最大化提高医院信誉。
                </div>
              </div>
              <div class="review-content-item">
                <div class="review-content-title">风向标</div>
                <div class="review-content-introduce">
                  医院等级评审是目前国内对医院进行综合能力评价的通用方式，是引导医院发展的“风向标”。通过医院等级评审工作，建立医院管理长效机制，为群众提供安全，有效，方便，低廉的医疗为啥服务。
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 项目内容 -->
        <div class="content-box" v-scroll-reveal>
          <div class="plan-title">
            <div class="flex title2">
              <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
              <span>项目内容</span>
              <img
                class="titleIcon1 rotate180"
                src="../assets/img/titleIcon1.png"
                alt=""
              />
            </div>
            <div class="flex mes2">
              <img src="../assets/img/titleIcon2.png" alt="" />
            </div>
          </div>
          <div>
            <div class="review-tab">
              <div
                :class="['review-tab-item', tab == 0 ? 'review-tab-item2' : '']"
                @click="tab = 0"
              >
                过程控制
              </div>
              <div
                :class="['review-tab-item', tab == 1 ? 'review-tab-item2' : '']"
                @click="tab = 1"
              >
                常见困难
              </div>
              <div
                :class="['review-tab-item', tab == 2 ? 'review-tab-item2' : '']"
                @click="tab = 2"
              >
                运作模式
              </div>
              <div
                :class="['review-tab-item', tab == 3 ? 'review-tab-item2' : '']"
                @click="tab = 3"
              >
                服务品质
              </div>
            </div>
            <div>
              <div v-if="tab == 0" class="review-tab-content">
                <div class="review-content-item content-item1">
                  条文现状调查<br />创建办构建<br />例会管理及督办机制<br />文件管理机制
                </div>
                <div class="review-content-item content-item2">
                  核心条款现场访查<br />质控体系构建及质量工具辅导<br />病例质量追踪调查
                </div>
                <div class="review-content-item content-item3">
                  院内专家组构建<br />自评机制建立<br />追踪检查法实例演示<br />模拟评审
                </div>
                <div class="review-content-item content-item4">
                  问题追踪整改<br />迎评手册<br />迎评技巧培训辅导
                </div>
                <div class="review-content-item2 content2-item1">准备阶段</div>
                <div class="review-content-item2 content2-item2">训练强化</div>
                <div class="review-content-item2 content2-item3">模拟评审</div>
                <div class="review-content-item2 content2-item4">评审冲刺</div>
              </div>
              <div v-else-if="tab == 1">
                <img
                  style="width: 100%"
                  src="../assets/img/review_15.png"
                  alt=""
                />
              </div>
              <div v-else-if="tab == 2">
                <div class="plan-introduce" style="text-align: center">
                  科学的项目运作模式确保项目顺利推进并达到预期效果
                </div>
                <img
                  style="width: 100%"
                  src="../assets/img/review_14.png"
                  alt=""
                />
              </div>
              <div v-else>
                <div class="plan-introduce" style="text-align: center">
                  目标明确，藉由评审，让医院管理能力，医疗质量服务品质得到整体提升
                </div>
                <div class="review-quality">
                  <div class="review-quality-item">
                    <div class="review-quality-name review-quality-icon1">
                      核心指导思想
                    </div>
                    <div class="review-quality-introduce">
                      <p>促进医院加强自身建设和管理,提高医疗质量，保证医疗安全</p>
                    </div>
                  </div>
                  <div class="review-quality-item">
                    <div class="review-quality-name review-quality-icon2">
                      辅导目的
                    </div>
                    <div class="review-quality-introduce">
                      <p>以核心条文深入辅导为主</p>
                      <p>构建三级质控体系并运作</p>
                      <p>有效推动登记评审工作</p>
                      <p>针对薄弱环节进行补强</p>
                    </div>
                  </div>
                  <div class="review-quality-item">
                    <div class="review-quality-name review-quality-icon3">
                      项目过程突出持续改进理念
                    </div>
                    <div class="review-quality-introduce">
                      <p>有计划，有制度，有规划</p>
                      <p>有学习，有培训，有授权</p>
                      <p>有措施，有落实，有成效</p>
                      <p>有检查，有分析，有反馈</p>
                      <p>有整改，有提高，有再修订（制度），有再培训</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="review-bnt" @click="openFun(true)">了解详情</div>
          </div>
        </div>
        <!-- 辅导过程 -->
        <div class="content-box" v-scroll-reveal>
          <div class="plan-title">
            <div class="flex title2">
              <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
              <span>辅导过程</span>
              <img
                class="titleIcon1 rotate180"
                src="../assets/img/titleIcon1.png"
                alt=""
              />
            </div>
            <div class="flex mes2">
              <img src="../assets/img/titleIcon2.png" alt="" />
            </div>
          </div>
          <div>
            <div class="review-tab">
              <div
                :class="['review-tab-item', tab1 == 0 ? 'review-tab-item2' : '']"
                @click="tab1 = 0"
              >
                项目辅导过程
              </div>
              <div
                :class="['review-tab-item', tab1 == 1 ? 'review-tab-item2' : '']"
                @click="tab1 = 1"
              >
                评审辅导流程
              </div>
              <div
                :class="['review-tab-item', tab1 == 2 ? 'review-tab-item2' : '']"
                @click="tab1 = 2"
              >
                辅导计划
              </div>
            </div>
            <div>
              <div v-if="tab1 == 0" class="review-tab-content2">
                <div class="review-content2-item">
                  <img
                    class="review-content2-img"
                    src="../assets/img/review_04.png"
                    alt=""
                  />
                  <div class="review-content2-box">
                    <div class="review-content2-box-name">01</div>
                    <div class="review-content2-box-introduce">
                      帮助医院建立科学的迎评机制，有计划，系统的推进评审工作。
                    </div>
                  </div>
                </div>
                <div class="review-content2-item">
                  <img
                    class="review-content2-img"
                    src="../assets/img/review_06.png"
                    alt=""
                  />
                  <div class="review-content2-box">
                    <div class="review-content2-box-name">02</div>
                    <div class="review-content2-box-introduce">
                      以评审工作为重点，提升医院内部管理，推动医疗质量持续改进。
                    </div>
                  </div>
                </div>
                <div class="review-content2-item">
                  <img
                    class="review-content2-img"
                    src="../assets/img/review_05.png"
                    alt=""
                  />
                  <div class="review-content2-box">
                    <div class="review-content2-box-name">03</div>
                    <div class="review-content2-box-introduce">
                      实时追踪评审筹备进度及计划执行效果。
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="tab1 == 1">
                <img
                  style="width: 100%"
                  src="../assets/img/review_17.png"
                  alt=""
                />
              </div>
              <div v-else-if="tab1 == 2">
                <img
                  style="width: 100%"
                  src="../assets/img/review_16.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <!-- 服务 -->
        <div class="content-box" v-scroll-reveal>
          <div class="plan-title">
            <div class="flex title2">
              <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
              <span>我们的服务</span>
              <img
                class="titleIcon1 rotate180"
                src="../assets/img/titleIcon1.png"
                alt=""
              />
            </div>
            <div class="flex mes2">
              <img src="../assets/img/titleIcon2.png" alt="" />
            </div>
          </div>
          <div>
            <div class="review-tab">
              <div
                :class="['review-tab-item', tab2 == 0 ? 'review-tab-item2' : '']"
                @click="tab2 = 0"
              >
                服务保障
              </div>
              <div
                :class="['review-tab-item', tab2 == 1 ? 'review-tab-item2' : '']"
                @click="tab2 = 1"
              >
                服务内容
              </div>
              <div
                :class="['review-tab-item', tab2 == 2 ? 'review-tab-item2' : '']"
                @click="tab2 = 2"
              >
                服务计划
              </div>
            </div>
            <div>
              <div v-if="tab2 == 0">
                <div class="plan-introduce" style="text-align: center">
                  致和咨询还将提供完善的售后服务和相关增值服务
                </div>
                <div class="review-mod">
                  <img
                    class="review-mod-img2"
                    src="../assets/img/review_09.png"
                    alt=""
                  />
                  <div class="review-mod-content">
                    <div class="review-content-item">
                      <div class="review-content-title1">售后服务</div>
                      <div class="review-content-introduce1">
                        1、项目完成后一年内免费提供一次现场指导<br />
                        2、终身享受免费远程指导<br />
                        3、免费提供公司管理月刊及成功经典案例汇编
                      </div>
                    </div>
                    <div class="review-content-item">
                      <div class="review-content-title2">增值服务</div>
                      <div class="review-content-introduce1">
                        1、搭建样板医院考察学习的桥梁<br />
                        2、优惠参加公司举办的各种学术研讨会和培训<br />
                        3、提供服务品牌建设，绩效成本管理，第三方满意度调查等增值产品
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="tab2 == 1">
                <div class="plan-introduce">
                  致和咨询为每所医院的医疗服务管理项目提供个性化工作计划，其工作内容精确到天数和人数，进而高效确认服务费用。
                </div>
                <img
                  style="width: 100%"
                  src="../assets/img/review_18.png"
                  alt=""
                />
              </div>
              <div v-else-if="tab2 == 2">
                <div class="plan-introduce" style="text-align: center">
                  为您带来高效，精准，优质，专业的咨询服务
                </div>
                <div class="review-service">
                  <div class="review-service-item">
                    <div class="review-service-div">医院管理水平整体提升</div>
                    <div class="review-service-div">
                      医院服务质量和流程逐步完善
                    </div>
                    <div class="review-service-div">
                      医院服务质量和流程逐步优化
                    </div>
                  </div>
                  <img
                    class="review-service-img"
                    src="../assets/img/review_19.png"
                    alt=""
                  />
                  <div class="review-service-item">
                    <div class="review-service-div">
                      医院质量与安全管理不断优化
                    </div>
                    <div class="review-service-div">
                      文化建设和发展规划有序推进
                    </div>
                    <div class="review-service-div">
                      医院质量与安全管理不断优化
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <serviceAcc :bg="2" />
      </div>
      <expertItemMod />
      <relation :open="open" @openFun="openFun"/>
    </div>
    <mobileReview
      class="mobile_review"
    />
  </div>
  
</template>

<script>
import expertItemMod from "../components/expertItemMod";
import serviceAcc from "../components/serviceAcc";
import relation from "../components/relation";
import mobileReview from '@/mobileViews/review.vue'
export default {
  components: {
    expertItemMod,
    serviceAcc,
    relation,
    mobileReview
  },
  data() {
    return {
      tab: 0,
      tab1: 0,
      tab2: 0,
      open:false,
    };
  },
  methods: {
    openPDF() {
      window.open(
        "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/other/protalDown/%E6%98%93%E7%AD%96%E5%92%A8%E8%AF%A2-%E7%AD%89%E7%BA%A7%E8%AF%84%E5%AE%A1.pdf"
      );
    },
    openFun(bo){
      this.open = bo
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-box {
  width: 100%;
  height: 400px;
  background-image: url("../assets/img/review.png");
  background-repeat: no-repeat;
  // background-position: 0 -181px;
  background-size: 100%;
  .banner-boxs {
    width: 1200px;
    margin: 0 auto;

    .banner-name {
      font-size: 52px;
      font-weight: 400;
      color: #fff;
      padding-top: 89px;
    }

    .banner-bnt {
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #2d43f2;
      border-radius: 50px;
      color: #fff;
      font-size: 16px;
      margin-top: 48px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background: #4273ff;
      }
    }
  }
}
.content-box {
  width: 1200px;
  margin: 0 auto;
}

.plan-introduce {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  margin-bottom: 30px;
}
.plan-title {
  padding-top: 79px;
}
.review-box {
  background-image: url("../assets/img/review_bg.png");
  background-repeat: repeat-y;
  background-size: contain;
}
.review-mod {
  display: flex;

  .review-mod-img {
    width: 622px;
    height: 493px;
    margin-right: 45px;
  }
  .review-mod-img2 {
    width: 562px;
    height: 446px;
    margin-right: 106px;
  }
  .review-mod-content {
    .review-content-item {
      margin-top: 42px;
      .review-content-title {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        position: relative;
        padding-left: 25px;
        &::before {
          content: "";
          width: 12px;
          height: 10px;
          background-image: url("../assets/img/review_02.png");
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          top: 9px;
          left: 0;
        }
      }
      .review-content-title1 {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        position: relative;
        padding-left: 43px;
        &::before {
          content: "";
          width: 30px;
          height: 30px;
          background-image: url("../assets/img/review_08.png");
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .review-content-title2 {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        position: relative;
        padding-left: 43px;
        &::before {
          content: "";
          width: 30px;
          height: 30px;
          background-image: url("../assets/img/review_07.png");
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .review-content-introduce {
        font-size: 18px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 20px;
      }

      .review-content-introduce1 {
        font-size: 16px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 15px;
        line-height: 36px;
      }
    }
  }
}
.review-tab {
  display: flex;
  justify-content: center;
  padding-bottom: 55px;
  .review-tab-item {
    padding: 16px 18px;
    border-bottom: 2px solid #c8cace;
    margin: 0 23px;
    cursor: pointer;
    transition: all 0.5s;
  }

  .review-tab-item2 {
    border-bottom: 3px solid #1e61ff;
    color: #1e61ff;
  }
}
.review-tab-content {
  width: 1200px;
  height: 545px;
  background-image: url("../assets/img/review_03.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  .review-content-item {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    position: absolute;
  }
  .content-item1 {
    top: 42px;
    left: 49px;
  }
  .content-item2 {
    top: 400px;
    left: 382px;
  }
  .content-item3 {
    top: 51px;
    left: 679px;
  }
  .content-item4 {
    top: 400px;
    left: 1020px;
  }
  .review-content-item2 {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
  }
  .content2-item1 {
    top: 340px;
    left: 81px;
  }
  .content2-item2 {
    top: 235px;
    left: 406px;
  }
  .content2-item3 {
    top: 340px;
    left: 727px;
  }
  .content2-item4 {
    top: 235px;
    left: 1052px;
  }
}
.review-bnt {
  width: 185px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #2d43f2;
  border-radius: 50px;
  color: #fff;
  margin: 46px auto 0;
  cursor: pointer;
}

.review-tab-content2 {
  display: flex;
  justify-content: space-between;
  .review-content2-item {
    width: 380px;
    height: 390px;
    position: relative;
    .review-content2-img {
      width: 380px;
      height: 244px;
    }
    .review-content2-box {
      position: absolute;
      top: 221px;
      left: 21px;
      width: 338px;
      height: 169px;
      background: #ffffff;
      box-shadow: 0px 3px 10px 1px rgba(146, 146, 146, 0.16);
      border-radius: 0px 0px 0px 0px;
      border: 1px solid #e1e1e1;
      .review-content2-box-name {
        font-size: 22px;
        font-weight: bold;
        color: #333333;
        margin-top: 31px;
        text-align: center;
      }
      .review-content2-box-introduce {
        padding: 17px 48px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
.review-quality {
  display: flex;
  justify-content: space-between;
  .review-quality-item {
    width: 378px;
    min-height: 221px;
    background: #ffffff;
    box-shadow: 0px 3px 10px 1px rgba(146, 146, 146, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 1px solid #e1e1e1;
    padding: 21px;
    .review-quality-name {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      padding-left: 46px;
    }
    .review-quality-icon1 {
      position: relative;
      &::before {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        top: -3px;
        left: 0;
        background-image: url("../assets/img/review_10.png");
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
    .review-quality-icon2 {
      position: relative;
      &::before {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        top: -3px;
        left: 0;
        background-image: url("../assets/img/review_11.png");
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
    .review-quality-icon3 {
      position: relative;
      &::before {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        top: -3px;
        left: 0;
        background-image: url("../assets/img/review_12.png");
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
    .review-quality-introduce {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      p {
        margin: 11px 0;
        padding-left: 20px;
        position: relative;
        &::before {
          content: "";
          width: 14px;
          height: 14px;
          position: absolute;
          top: 2px;
          left: 0;
          background-image: url("../assets/img/review_13.png");
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}
.review-service {
  display: flex;
  justify-content: space-between;
  .review-service-item {
    width: 350px;
    .review-service-div {
      padding: 53px 10px 53px 18px;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      position: relative;
      &::before {
        content: "";
        width: 12px;
        height: 10px;
        position: absolute;
        top: calc(50% - 5px);
        left: 0;
        background-image: url("../assets/img/review_02.png");
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
  }
  .review-service-img {
    width: 412px;
    height: 372px;
  }
}
.mobile_review {
  display: none;
}
@media screen and (max-width: 650px) {
  .mobile_review {
    display: block;
    background-color: #fff;
  }
  .pc_review {
    display: none;
  }
}
</style>
